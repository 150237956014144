import React, { useEffect, useState } from "react"
import useThemeController from "aldoo-ra/ThemeController"
import PlatformSelector from "../../components/Site/PlatformSelector"
import Value from "aldoo-ra/Value"
import AndroidHome from "../android/+Page"
import iOSHome from "../ios/+Page"

const homeComponents = {
  android: AndroidHome,
  ios: iOSHome,
  windows: AndroidHome,
}

const HomePage = () => {
  const [platform] = Value("site_platform")
  const [isTransitioning] = Value("page_transition")
  const Home = homeComponents[platform]
  useThemeController()

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  return (
    <div className="relative">
      <PlatformSelector />
      <div
        className={`transition-opacity duration-300 ${
          isTransitioning ? "opacity-0" : "opacity-100"
        }`}
      >
        {Home && <Home />}
      </div>
    </div>
  )
}

export default HomePage
